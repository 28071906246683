import React, { useState, useEffect } from "react";
import './homepage.css'; // make sure to create this file and write corresponding styles
import icon1 from './icons/icon1.png'; // import your icons
import icon2 from './icons/icon2.png';
import icon3 from './icons/icon3.png';
import icon4 from './icons/icon4.png';
import icon5 from './icons/icon5.png';
import icon6 from './icons/icon6.png';
import icon7 from './icons/icon7.png';
import icon8 from './icons/icon8.png';
import icon9 from './icons/icon9.png';
import icon10 from './icons/icon10.png';
import icon11 from './icons/icon11.png';
import section1Img from './image1.png';
import section2Img from './image2.png';
import section3Img from './image3.png';
import twitterIcon from './icons/twitter.png';
import instagramIcon from './icons/instagram.png';
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { nfts } from './data'; // Ensure path is correct
import './Gallery.css'; // Import CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import ConnectButton from "./ConnectButton";

import { Link as ScrollLink } from 'react-scroll';
import { scroller } from 'react-scroll';



const Homepage = ({ isConnected, setIsConnected, address, setAddress }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact"); // Redirects to your contact page
  };

  const location = useLocation();

useEffect(() => {
    if (location.hash) {
        let elemId = location.hash.replace("#", "");
        scroller.scrollTo(elemId, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    }
}, [location]);




  return (
  
    <div className="homepage">
      <div className="section-1">
        <div className="section-1-content">
          <h1 className="left-title">CASAS DEL MUNDO REAL EN BLOCKCHAIN</h1>
          <h2 className="left-subtitle"><br></br>Invierte. Tokeniza tu inmueble. Gana rentas del alquiler. Compra y vende tu apartamento en la plataforma DEFI colaborativa mas popular de la galaxia.</h2>
          <div style={{display:'flex', flexDirection:'row'}}>
          <ScrollLink
            to="apartments"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="home-button"
          >
            Ver apartamentos
          </ScrollLink>
          <ConnectButton
            isConnected={isConnected}
            setIsConnected={setIsConnected}
            address={address}
            setAddress={setAddress}
          />

          </div>

        </div>
        <div className="image1-container">
          <img className="section-1-image" src={section1Img} alt="section1" />
        </div> 
      </div>
      <div className="section-2">
        <h1 className="center-title">SELECCIONA INMUEBLES Y CIUDAD</h1>
        <h2 className="center-subtitle">Nuestra cuidada y estudiada selección de inmuebles permite que inversores como tú saquen la mayor rentabilidad de su dinero</h2>
        <img className="section-2-image" src={section2Img} alt="section1" />
      </div>  
      <div className="section-highlighted-properties" id="apartments"  >
          <h1 className="center-title">Los apartamentos más rentables</h1>
          <h2 className="center-subtitle">Nuestra cuidada y estudiada selección de inmuebles permite que inversores como tú saquen la mayor rentabilidad de su dinero</h2>
          
          <div className="gallery">
          {nfts.slice(0, 3).map((nft) => (
    <div key={nft.id} className="card" >
          <div className="video-container">
            <iframe 
              src={nft.videoURL} 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              title={nft.name}>
            </iframe>
          </div>
          <div className="card-content">
            <div className="title-price">
              <h2>{nft.name}</h2>
              <p>{`${nft.price}€`}</p>
            </div>
            <div className="location">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>{nft.description}</p>
            </div>
            <Link className="link" to={`/nft/${nft.id}`}>VER DETALLES</Link>
          </div>
          
        </div>
        
      ))}
    </div><div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', marginTop:'-50px', marginBottom:'100px'}}><Link className="home-button" to={`/gallery`}>VER MAS</Link></div>
      </div>

      
      <div className="section-2" id="proyecto">
        <h1 className="center-title">VENTAJAS DE INVERTIR CON PRMUT</h1>
        <h2 className="center-subtitle">Contamos con años de experiencia en explotación de apartamentos turísticos y sabemos de su gran rentabilidad</h2>
        
        <div className="grid">
          <div className="grid-item">
            <img src={icon1} alt="icon1"/>
            <h3>Sin Minimo: Desde 1 centimo</h3>
            <h4>Invierte en un pedazo de casa, usando
              staking de inversión participativa.
              Conviertete en propietario y casero y
              disfruta de tu parte del alquiler y la venta.</h4>
          </div>
          <div className="grid-item">
            <img src={icon2} alt="icon2"/>
            <h3>Sin Barreras: Abierto, Anonimo</h3>
            <h4>Tus datos a salvo. Invierte sin compartir
            ningún dato de identidad. Todo mediante tu
            billetera pseudonima. CASO DE USO: Dona tus
            propiedades a tu hijo, sin burocracia solo
            cediendole las contraseñas.</h4>
          </div>
          <div className="grid-item">
            <img src={icon3} alt="icon3"/>
            <h3>Desde cualquier parte del mundo</h3>
            <h4>nvierte desde ciudades lejanas a un click de
            distancia: CASO DE USO: Diversifica tus
            inveriones, reduce el riesgo de tener todo tu
            dinero en el mismo pais. Combate la inflación.</h4>
          </div>
          <div className="grid-item">
            <img src={icon4} alt="icon4"/>
            <h3>Maxima Rentabilidad Momentum</h3>
            <h4>En finazas descentralizadas DEFI un &gt;20%
            de rentabilidad es habitual. En el mundo
            inmobiliario también se consigue innovando
            si aprovechas el momento. En 2023 es
            combinación ganadora juntar Alquiler
            Turistico y Compra de Locales para hacer
            apartamentos.</h4>
          </div>
          <div className="grid-item">
            <img src={icon5} alt="icon5"/>
            <h3>Alquiler Turístico y Corta Estancia</h3>
            <h4>Las rentabilidades superan el X3 en
            subarriendo. Las prohibiciones de 2020 al
            2022 redujeron la oferta de apartamentos
            turisticos y la demanda es superior a
            2019. Por eso los precios están como
            nunca</h4>
          </div>
          <div className="grid-item">
            <img src={icon6} alt="icon6"/>
            <h3>Compra de Locales para Vivienda</h3>
            <h4>Las prohibiciones 2020-2022 arruinaron al
            comercio y muchos locales aun están a la
            venta. En muchos de ellos se pueden
            sacar varias viviendas a un precio 2 veces
            inferior a comprar una vivienda</h4>
          </div>
          <div className="grid-item">
            <img src={icon7} alt="icon6"/>
            <h3>Gana también con la venta del inmueble</h3>
            <h4>Cuando un bien es muy liquido su precio
            es más alto pues hay mas compradores.
            Por eso vender tu inmueble en el
            mercado mundial PRMUT hace que
            alcance mayor precio de venta.
            Aprovecha v se el primero.
            </h4>
          </div>
          <div className="grid-item">
            <img src={icon8} alt="icon6"/>
            <h3>Cobro mensual automatico del alquiler</h3>
            <h4>Gana alquiler todos los meses, haciendo
            un X 1,20 anual. Tu parte del alquiler se
            deposita en tu Staking los primeros 5 dias
            de cada mes en USDT para que retires o
            reinviertas. Si inviertes 100000 USDT
            ganaras 20000 euros al año, 1800 euros al
            mes disponibles para lo que tu quieras
            </h4>
          </div>
          <div className="grid-item">
            <img src={icon9} alt="icon6"/>
            <h3>Garantizado por una vivienda real</h3>
            <h4>El inmueble en que inviertas hace de
            colateral
            Es como si los dolares estuvieran
            respaldados por oro. En este caso es un
            inmueble el que garantiza que ne el peor
            de los casos que puedas vender la casa
            para recuperar tu
            dinero
            </h4>
          </div>
          <div className="grid-item">
            <img src={icon10} alt="icon6"/>
            <h3>Reclama tu casa ante Notario</h3>
            <h4>Sí tienes el total del NFT, es decir
            el total del inmueble, puedes
            reclamar ante notario escriturar a
            tu nombre en el mundo real y
            actualizar el registro de la
            propiedad. Este tramite sería
            presencial y acudrirá el autorizado
            de PRMUT para firmar las
            escrituras</h4>
          </div>
          <div className="grid-item">
            <img src={icon11} alt="icon6"/>
            <h3>Filosofía cripto, usar la tecnología para dar libertad al individuo</h3>
            <h4>Buscamos la mayor descentralización
            de la plataforma y el mercado de
            PRMUT.
            Haciendo que cualquiera pueda
            tokenizar su inmueble con garantia
            hipotecaria y dar alternativas de venta y
            alanilan liknan v. DOD</h4>
          </div>
        </div>
      </div>
      <div className="section-2" id="invierte">
        <h1 className="center-title">Como se Invierte</h1>
        <img className="section-3-image" src={section3Img} alt="section1" />
      </div>  
      <div className="section-help">
        <div className="help-text">
          <h1 className="help-title">¿Tienes dudas?</h1>
          <h2 className="help-subtitle">Estaremos encantados de ayudarte</h2>
        </div>
        <button className="help-button" onClick={handleButtonClick}>Contacta con nosotros</button>
      </div>
      <footer>
        <div className="footer-content">
          <div className="footer-content2">
          <h2 className="footer-subtitle">Redes Sociales</h2>
          <div className="social-icons">
            <a href="https://twitter.com/yourTwitterHandle" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a href="https://instagram.com/yourInstagramHandle" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" />
            </a>
          </div>
          </div>
          <p className="footer-text">© {new Date().getFullYear()} PRMUT. All rights reserved.</p>
        </div>
      </footer>
    </div>
      
  
   
  );
};

export default Homepage;
