import React from 'react';
import { useParams } from 'react-router-dom';
import { nfts } from './data';
import './NFT.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import openSeaLogo from './opensea.png'; // Import your image

function NFT({ isConnected, address }) {
  const { id } = useParams();
  const nft = nfts.find(n => n.id === Number(id));
  console.log("Is Connected in NFT:", isConnected);

  if (!nft) return <div>NFT not found</div>

  // Build the OpenSea URL dynamically
  const openSeaURL = `https://opensea.io/assets/matic/0x51bffb10dd4fac8f25176053a0bb2f0fa15a02cf/${nft.id}`;

  return (
    <div className="nftCard">
      <div className="nftVideo-container">
        <iframe 
          src={nft.videoURL} 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
          title={nft.name}>
        </iframe>
      </div>
      <div className="nftCard-content">
        <div className='title-price2'>
          <h1>{nft.name}</h1>
          <p>{`${nft.price}€`}</p>
        </div>
        <div className="nftLocation">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <h2>{nft.description}</h2>
        </div>
        <div className="nftDetails">
          <h2>Owner</h2>
          <h3>{nft.owner}</h3>
          <h2>Contract</h2>
          <h3>{nft.contract}</h3>
        </div>
        
        <div className="nftButtons">
          <a href={nft.tokenization} target="_blank" rel="noopener noreferrer" className="nftButton">Tokenization</a>
          {isConnected && address === '0x0d4d39465bBDa4E17251Eb944C789D63b8200D47' ? (
          <a href={nft.deed} target="_blank" rel="noopener noreferrer" className="nftButton">Deed</a>
        ) : (
          <button disabled className="nftButton">Connect Owner's Wallet to View Deed</button>
        )}
          <a href={nft.registry} target="_blank" rel="noopener noreferrer" className="nftButton">Registry</a>
          <a href={openSeaURL} target="_blank" rel="noopener noreferrer" className="nftButton openseaButton">
            <img src={openSeaLogo} alt="OpenSea Logo" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NFT;
