import React from "react";
import { toChecksumAddress } from "web3-utils";
import './homepage.css'

const ConnectButton = ({ isConnected, setIsConnected, setAddress }) => {
  
  const connect = async () => {
    if (window.ethereum) {
      try {
        if (isConnected) {
          window.ethereum.autoRefreshOnNetworkChange = false;
          await window.ethereum.request({ method: "eth_requestAccounts" });
          window.ethereum.autoRefreshOnNetworkChange = true;
          setAddress("");
          setIsConnected(false);
        } else {
          const accounts = await window.ethereum.enable();
          const address = toChecksumAddress(accounts[0]);
          setAddress(address); // Set the address prop here
          setIsConnected(true);
          console.log(address)
        }
       
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Non-Ethereum browser detected. You should consider trying MetaMask!");
    }
  };

  return (
    <div>
      <a href="/"></a>
      <button className="connect-button" type="submit" onClick={connect}>
        {isConnected ? <p>Disconectar</p> : <p>Conectar</p>}
      </button>
    </div>
  );
}

export default ConnectButton;
