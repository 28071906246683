import React from "react";
import './Contact.css';
import emailjs from 'emailjs-com';

const Contact = () => {

  const handleSubmit = event => {
    event.preventDefault();

    // Access values from .env file
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    // Retrieve form data
    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;

    // Create the email template parameters based on your template setup on EmailJS
    const templateParams = {
      from_name: name,
      from_email: email,
      message_html: message
    };

    // Send the email
    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Correo enviado con éxito!', response);
        alert("Correo enviado con éxito!");
      })
      .catch((error) => {
        console.error('Failed to send email. Error:', error);
        alert("Error al enviar el correo. Por favor, inténtalo de nuevo más tarde.");
      });
  };

  return (
    <div className="contact-page">
      <h1 className="contact-title">Contacta con nosotros</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Nombre</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Mensaje</label>
        <textarea id="message" name="message" required />

        <button type="submit" className="contact-submit-button">Enviar</button>
      </form>
    </div>
  );
};

export default Contact;
