export const nfts = [
  {
    id: 0,
    name: 'LV5B',
    description: 'Calle Lucio del Valle 5, 20003 Madrid, España    ',
    videoURL: 'https://www.youtube-nocookie.com/embed/ijJ2NUjII6M',
    tokenization: 'https://ipfs.io/ipfs/bafybeicpy7dwexm2dvfzz7ee6qxht6woy5m35b3y5epreh4rd3qxcvnxra/TokenizationRecord.pdf',
    deed:'https://ipfs.io/ipfs/bafybeiga5fdib7z7wngt3k4wulsaqhj7rgk4xyxmza2tx6ucojzuybguda/propertyDeed.pdf',
    registry:'https://ipfs.io/ipfs/bafybeigedm5swydskk4vvxp3efthvcxcmen4hlqdrzet4nzexhtqbdv3ja/LandRegistry.pdf',
    owner:'0x01fb8F7b8560729F6934f1Ff1B7780447a8495Ae',
    contract:'0x51bfFb10DD4FAc8F25176053a0Bb2F0Fa15a02cF',
    price: '500000',
  },  
  {
    id: 1,
    name: 'LV5F',
    description: '3º F en Calle Lucio Del Valle, 5, 28003 Madrid',
    videoURL: 'https://www.youtube-nocookie.com/embed/kHzSLiVfz3o',
    tokenization: 'https://ipfs.io/ipfs/bafybeigdqzn2gmgqixlgoukdnhmip4bf22mlhbv62bedwesknddhgghk24/TokenizationRecord.pdf',
    deed:'https://ipfs.io/ipfs/bafybeiga5fdib7z7wngt3k4wulsaqhj7rgk4xyxmza2tx6ucojzuybguda/propertyDeed.pdf',
    registry:'https://ipfs.io/ipfs/bafybeigedm5swydskk4vvxp3efthvcxcmen4hlqdrzet4nzexhtqbdv3ja/LandRegistry.pdf',
    owner:'0x01fb8F7b8560729F6934f1Ff1B7780447a8495Ae',
    contract:'0x51bfFb10DD4FAc8F25176053a0Bb2F0Fa15a02cF',
    price: '250000',
  },
  {
    id: 2,
    name: 'GAC28',
    description: 'Bajo C en Calle General Alvarez De Castro, 28, Madrid 28010 Madrid    ',
    videoURL: 'https://www.youtube-nocookie.com/embed/xRPIihLyzdA',
    tokenization: 'https://ipfs.io/ipfs/bafybeiddtc3gkulrv5xsefpcic7ammk5xdky3ts24k6vramgsty2fzhuxi/TokenizationRecord.pdf',
    deed:'https://ipfs.io/ipfs/bafybeibfknlvxzqlkdwdvjyml5qadpahnrhxg3zl6zb5rxokph2e2asixy/propertyDeed.pdf    ',
    registry:'https://ipfs.io/ipfs/bafybeibrxiqvlvylu3trga6emqod2hbwriksydj3vield2ghxwhfcchwni/LandRegistry.pdf',
    owner:'0x01fb8F7b8560729F6934f1Ff1B7780447a8495Ae',
    contract:'0x51bfFb10DD4FAc8F25176053a0Bb2F0Fa15a02cF',
    price: '300000',
  },
  {
    id: 3,
    name: 'EG14',
    description: '2º YD en Calle Eloy Gonzalo, 14, 28010 Madrid',
    videoURL: 'https://www.youtube-nocookie.com/embed/F0nla8NtKww',
    tokenization: 'https://ipfs.io/ipfs/bafybeialmpqiyzpr3xti2j3d4jm4ffkjw6gemj7a5qupg7cpzgacbnugoy/TokenizationRecord.pdf',
    deed:'https://ipfs.io/ipfs/bafybeiexberbn5ztr3fnkkq7fgmccg3zlvrioq6oqdwmnls5h76t5fkmlu/propertyDeed.pdf',
    registry:'https://ipfs.io/ipfs/bafybeigvt6egkehcgcezyv3a6slcicmsip5lcwvo2gfzlgjj3pxykq4laa/LandRegistry.pdf',
    owner:'0x01fb8F7b8560729F6934f1Ff1B7780447a8495Ae',
    contract:'0x51bfFb10DD4FAc8F25176053a0Bb2F0Fa15a02cF',
    price: '400000',
  },
  {
    id: 4,
    name: 'GVH5',
    description: 'Bajo 01 en Glorieta Vallehermoso, 5, Madrid 28003 Madrid',
    videoURL: 'https://www.youtube-nocookie.com/embed/RNt47Wt1dC8',
    tokenization: 'https://ipfs.io/ipfs/bafybeid7ny6auqukng3uw3dygtue3uw5ur5zgwmltlsnajp5c7avvi6xze/TokenizationRecord.pdf',
    deed:'https://ipfs.io/ipfs/bafybeigrjtv4zu2fmmuxcyplpjncrg5rudjdh5q42ug676ik2qhaq3cdme/propertyDeed.pdf',
    registry:'https://ipfs.io/ipfs/bafybeiahditjbnxnx5mq3x7xddbldqvcacaj5awbrg5czuj5tdfrfcr47e/LandRegistry.pdf',
    owner:'0x01fb8F7b8560729F6934f1Ff1B7780447a8495Ae',
    contract:'0x51bfFb10DD4FAc8F25176053a0Bb2F0Fa15a02cF',
    price: '120000',
  },
  
  

  
  // ...additional NFTs
];