import React from 'react';
import { Link } from 'react-router-dom';
import { nfts } from './data'; // Ensure path is correct
import './Gallery.css'; // Import CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function Gallery() {
  return (
    <div className="gallery">
      {nfts.map((nft) => (
        <div key={nft.id} className="card">
          <div className="video-container">
            <iframe 
              src={nft.videoURL} 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              title={nft.name}>
            </iframe>
          </div>
          <div className="card-content">
            <div className="title-price">
              <h2>{nft.name}</h2>
              <p>{`${nft.price}€`}</p>
            </div>
            <div className="location">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <p>{nft.description}</p>
            </div>
            <Link className="link" to={`/nft/${nft.id}`}>VER DETALLES</Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Gallery;
