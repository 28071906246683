import React, { useState } from "react";
import ConnectButton from "./ConnectButton";
import Gallery from './Gallery';
import NFT from './NFT'; 
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import Link
import logo from './logo.png';
import Homepage from "./Homepage";
import Contact from "./Contact";
import './styles.css'
import { Link as ScrollLink } from 'react-scroll';

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  
  return (
    <Router>
      <div>
      <header className="App-header">
      <div className="header-top">
    <a className="header-text" href="https://prmut.com"> 
    <img src={logo} className="logo"></img>
    </a>
    <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className="mobile-menu-button">
        ☰
    </button>
</div>
    <div className={`header-right ${mobileMenuOpen ? 'open' : ''}`}> 
                        <Link className="header-text" to="/">
                            <p className="header-inmuebles">Portada</p>
                        </Link>
                        <Link className="header-text" to="/gallery">
                            <p className="header-inmuebles">Inmuebles</p>
                        </Link>
                        <Link className="header-text" to="#invierte">
                            <p className="header-inmuebles2">Como se Invierte</p>
                        </Link>
                        <Link className="header-text" to="#proyecto">
                            <p className="header-inmuebles2">Nuestro Proyecto</p>
                        </Link>
                        <Link className="header-text" to="/contact">
                            <p className="header-inmuebles">Contacto</p>
                        </Link>
                    </div>
                </header>

        <Routes>
        <Route path="/" element={<Homepage isConnected={isConnected} setIsConnected={setIsConnected} address={address} setAddress={setAddress} />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/nft/:id" element={<NFT isConnected={isConnected} address={address} />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
